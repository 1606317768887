import request from './request';

// 登陆 - 查看用户所有的用户
export function agent_login_data(data) {
  return request({
    url: '/v2/public/login/agent_login_data',
    method: 'post',
    data
  });
}

// 登录操作 - 选择用户
export function agent_login(data) {
  return request({
    url: '/v2/public/login/agent_login',
    method: 'post',
    data
  });
}

// 代理商登出
export function agent_login_out(data) {
  return request({
    url: '/v2/agent/agent_login_out',
    method: 'post',
    data
  });
}

// 手机号获取验证码
export function get_login_code(data) {
  return request({
    url: '/v2/public/get_login_code',
    method: 'post',
    data
  });
}

// 图片上传
export function public_photo_add(data) {
  return request({
    url: '/v2/public/agent/public_photo_add',
    method: 'post',
    data
  });
}

// 营业执照ocr
export function business_ocr(data) {
  return request({
    url: '/v2/public/business_ocr',
    method: 'post',
    data
  });
}

// 服务商信息查看
export function setting_sel(data) {
  return request({
    url: '/v2/agent/setting/setting_sel',
    method: 'post',
    data
  });
}