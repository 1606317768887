import "@arco-design/web-react/es/Modal/style";
import _Modal from "@arco-design/web-react/es/Modal";
import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
// 创建实例
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

// 请求前拦截
service.interceptors.request.use(config => {
  // 如果定义了baseUrl则替换
  if (config.headers.replaceBaseUrl) {
    config.baseURL = config.headers.replaceBaseUrl;
  }

  // token
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['token'] = token;
  }
  return config;
}, error => {
  console.error('网络请求错误', error); // for debug
  return Promise.reject(error);
});
service.interceptors.response.use(response => {
  const res = response.data;
  const config = response.config;
  if (res.code !== 200) {
    // 请求失败

    // preventErrMsg 用来阻止message弹出错误而针对一些特殊code自行处理
    if (!config.preventErrMsg) {
      _Message.error(res.msg || 'Error');
    }

    // 50008: 登录过期
    if (res.code === 50008) {
      _Modal.confirm({
        title: '登陆已过期',
        content: '登录过期，请重新登录',
        okButtonProps: {
          status: 'danger'
        },
        onOk: () => {
          // 跳转登陆页
          window.location.href = '/login';
        }
      });
    }
    return Promise.reject(res);
  } else {
    const payload = res.payload;
    return payload;
  }
}, error => {
  console.log('err' + error); // for debug
  _Message.error(error.message || 'Error');
  return Promise.reject(error);
});
export default service;