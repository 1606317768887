/**
 * 状态管理 - 简单点，有时间记得封装
 */

import defaultSettings from '../settings.json';
const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {
  permissions: {}
};

/**
 * @param globalParams 公共参数
 * @param userInfo 用户信息
 * @param userLoading 用户信息加载
 * @param webHostSetting 域名对应配置
 */
const initialState = {
  globalParams: {
    type: 'saas',
    loginType: '1'
  },
  settings: defaultSettings,
  userInfo: userInfo,
  webHostSetting: {}
};

// console.log('debug: 监听store变化', initialState);

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-web-host-settings':
      {
        // 当前域名对应的配置信息
        const webHostSetting = action.payload;
        return {
          ...state,
          webHostSetting
        };
      }
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return {
          ...state,
          settings
        };
      }
    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        return {
          ...state,
          userLoading,
          userInfo
        };
      }
    default:
      return state;
  }
}